
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ItemHeader',
  components: {
    ...importComponents(
    ),
  },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    return {};
  },
});
