<template>
    <div class="item-wrapper">
        <slot/>
    </div>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';

export default defineComponent({
  name: '',
  components: {
    ...importComponents(
    ),
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';
    .item-wrapper{
        border-top: 8px solid $theme-color;
        width: 100%;
    }
</style>
