<template>
    <header>
        <h2>
            {{title}}
        </h2>
    </header>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ItemHeader',
  components: {
    ...importComponents(
    ),
  },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';

    header {
         width: 100%;
        h2 {
            text-align: center;
            font-weight: bold;
            color: $theme-color;
            margin-top: 50px;
            margin-bottom: 69px;
            font-size: 25px;
            line-height: 30px;
        }
    }
</style>
